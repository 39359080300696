import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Arrow from "../images/arrow.inline.svg";
import Layout from "../components/layout";
import Seo from "../components/seo";

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <article className="article flow">
      <div className="hero-image-wrapper">
        <StaticImage
          className="hero-image"
          src="../images/main-home.jpg"
          width={637}
          height={637}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="a hand full of dirt"
        />
      </div>
      <div className="headline">
        <h1>
          Carbon: Our
          <br />
          Shared Opportunity
        </h1>
      </div>
      <div className="article-wrapper flow">
        <p>
          The Agoro Carbon Alliance is taking action on a global scale to
          reverse the effects of climate change by decarbonizing farming and
          restoring carbon to the world’s soil.
        </p>
        <p>
          Backed by Yara, one of the world leaders in crop nutrition, we have
          the agronomic knowledge, global network and technical expertise to
          make carbon cropping practical and profitable for all of our Alliance
          partners.
        </p>
        <p>
          Watch the global launch event that was livestreamed on June 8, 2021 to
          learn how the Agoro Carbon Alliance is supporting decarbonizing the
          global food value chain.
        </p>
        <h2>Launch Event Livestream</h2>
        <div className="embed-container">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/oRKmlQifpSM"
            title="YouTube video player: Agoro Carbon Alliance - Global Launch Event"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            loading="lazy"
          ></iframe>
        </div>
        <p>
          We activate Yara’s deep agronomic knowledge and credibility with new
          technology to enable Farm-Based Carbon Solutions that support and
          incentivize farmers’ transition to carbon cropping practices. This
          transformation is the basis of Agoro Carbon’s reliable, high-quality
          Farm Carbon Credits and establishes a certified climate-smart farm
          value chain from soil to consumer. Each member of the Alliance has a
          responsibility - and an incentive - to play a collaborative role in
          creating our climate-positive future.
        </p>
      </div>
      <div className="button-group floating-col flow">
        <div className="flow">
          <h3>Farmers/Advisors</h3>
          <p>
            Add extra revenue and enhance soil health by introducing carbon
            cropping practices into your operation.
          </p>
          <Link to="/farmers-advisors/" className="btn btn--primary">
            Get Started
            <span className="sr-only"> for Farmers/Advisors</span>
            <Arrow
              width={22}
              height={20}
              aria-hidden="true"
              focusable="false"
            />
          </Link>
        </div>
        <div className="flow">
          <h3>Partners/Supporters</h3>
          <p>
            Become a part of enabling a climate-positive food future, enabling
            farmers to decarbonize their operations.
          </p>
          <Link to="/partners-supporters/" className="btn btn--primary">
            Learn More
            <span className="sr-only"> about Partners/Supporters</span>
            <Arrow
              width={22}
              height={20}
              aria-hidden="true"
              focusable="false"
            />
          </Link>
        </div>
      </div>
    </article>
  </Layout>
);

export default IndexPage;
